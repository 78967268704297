<template>
  <div v-if="role === 0">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>
              Changes to your plan or number of licenses may take 2-3 minutes to
              become active.
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div id="billing">
      <div id="profile-embed" :key="componentKey"></div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Sorry, it seems like you do not have access to this page.</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, reactive, ref, watch } from "@vue/runtime-core";

export default {
  name: "VbBilling",
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    const token = ref("");
    const role = ref(1);
    token.value = user.value.outsetaToken;
    role.value = user.value.currentOrg.role;
    var componentKey = 0;

    const currentOrg = reactive({});
    Object.assign(currentOrg, user.value.currentOrg);

    watch(user.value, () => {
      role.value = user.value.currentOrg.role;
      token.value = user.value.outsetaToken;
      Object.assign(currentOrg, user.value.currentOrg);
    });

    return { token, role, currentOrg, componentKey };
  },
  mounted() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    store.dispatch("user/OUTSETA_REPORT", {
      activityType: " go to billing page in Dashboard",
      email: user.value.email,
    });
    // if (this.role === 0) {
    //   this.setUpOutseta();
    // }
  },
  unmounted() {
    // if (this.role === 0) {
    //   this.removeOutseta();
    // }
  },
  methods: {},
};
</script>
